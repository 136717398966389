<template>
<ProfileCard
    :name="data.name"
    :credits="$store.state.account.data.tokens"
    :avatar="$store.state.account.data.avatar"
    :tokens="$store.state.account.data.credits"
    @newAvatar="setAvatar"
  />

  <form class="_form _relative" @submit.prevent="handleSubmit(v$.$invalid, v$Password.$invalid)">
    <Loader v-if="isLoading" isOverlay />
    <label class="p-field mb-4" :class="{'p-invalid': v$.name.$invalid && v$.name.$dirty}">
      <span class="p-label" v-t="'username'"></span>
      <InputText type="text" v-model="v$.name.$model" @blur="v$.name.$touch()" />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}">
      <span class="p-label" v-t="'email'"></span>
      <InputText type="email" v-model="v$.email.$model" @blur="v$.email.$touch()" />
      <span class="p-hint" v-t="'profile.this_is_your_login'" />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.phone.$invalid && v$.phone.$dirty}">
      <span class="p-label" v-t="'profile.phone'"></span>
      <InputText type="tel" v-model="v$.phone.$model" @blur="v$.phone.$touch()" />
    </label>

    <button class="_link" type="button" v-t="isShow ? 'cancel' : 'profile.change_password'" @click="isShow = !isShow"></button>

    <div class="_password-box mt-4" v-if="isShow">
      <label class="p-field mb-4" :class="{'p-invalid': v$Password.old_password.$invalid && v$Password.old_password.$dirty}">
        <span class="p-label" v-t="'old_password'"></span>
        <InputText type="password" v-model="v$Password.old_password.$model" @blur="v$Password.old_password.$touch()" />
      </label>
      <label class="p-field mb-4" :class="{'p-invalid': v$Password.new_password.$invalid && v$Password.new_password.$dirty}">
        <span class="p-label" v-t="'new_password'"></span>
        <InputText type="password" v-model="v$Password.new_password.$model" @blur="v$Password.new_password.$touch()" />
        <span v-if="v$Password.new_password.$invalid && v$Password.new_password.$dirty && v$Password.new_password.minLength">
          {{$t('profile.password_min_length', { number: 6 })}}
        </span>
      </label>
      <label class="p-field" :class="{'p-invalid': v$Password.replay_new_password.$invalid && v$Password.replay_new_password.$dirty}">
        <span class="p-label" v-t="'replay_new_password'"></span>
        <InputText type="password" v-model="v$Password.replay_new_password.$model" @blur="v$Password.replay_new_password.$touch()" />
        <span v-if="v$Password.replay_new_password.$dirty && v$Password.new_password.$model && v$Password.replay_new_password.$model !== v$Password.new_password.$model" v-t="'profile.password_confirm'" />
      </label>
    </div>

    <Button class="p-button-big _full-width mt-5" type="submit" :disabled="isLoading"><span v-t="'save_change'"></span></Button>
  </form>
</template>

<script>
import { ref, computed, reactive } from 'vue';
import { useStore } from 'vuex'
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import { email, required, sameAs, minLength } from "@vuelidate/validators";

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { Loader } from '../../components/ui'
import { ProfileCard } from '../../components/common'

export default {
  components: {
    ProfileCard,
    InputText,
    Button,
    Loader,
  },
  setup() {
    const store = useStore()
    const isShow = ref()
    const { t } = useI18n()
    const toast = useToast()

    const password = reactive({
      old_password: '',
      new_password: '',
      replay_new_password: '',
    })

    const rules = reactive({
      email: { required, email },
      name: { required },
      phone: { required },
    });

    const rulesForPassword = reactive({
      old_password: { required },
      new_password: { required, minLength: minLength(6) },
      replay_new_password: { required }
    });

    const data = computed(() => {
      return {
        name: store.state.account.data.name,
        email: store.state.account.data.email,
        phone: store.state.account.data.phone,
        // avatar: store.state.account.data.avatar,
        // tokens: store.state.account.data.tokens,
        // credits: store.state.account.data.credits,
      }
    })
    const isLoading = computed(() => store.state.account.isLoading)

    const v$ = useVuelidate(rules, data)
    const v$Password = useVuelidate(rulesForPassword, password)

    const handleSubmit = (isFormValid, isPasswordValid) => {
      let body = {...data.value}
      v$.value.$touch()
      if (isShow.value) {
        v$Password.value.$touch()
      }
      if (isFormValid || isShow.value && isPasswordValid && password.replay_new_password !== password.new_password) {
        return;
      }
      if (isShow.value) {
        body = {...body, ...password}
      }

      store.dispatch('account/sendBase', {
        body,
        toast,
        t,
        bodyType: 'formData'
      })
    }

    function setAvatar(file) {
      data.value.avatar = file
    }

    return {
      v$,
      v$Password,
      isShow,
      password,
      handleSubmit,
      data,
      isLoading,
      setAvatar,
    }
  }
}
</script>
